import React from "react"
import styled from "styled-components"

import colorHelper from "@helpers/color"

import triangleWhiteSvg from "@assets/jobs/triangle-white.svg"
import UnstyledLink from "@components/elements/UnstyledLink"

const FlexItemBox = styled(UnstyledLink)`
  flex: 1 0;

  height: 40px;
  color: white;
  background-color: ${props => props.background};

  justify-content: center;
  align-items: center;
  display: flex;
`

const InnerFlexContainerArrow = styled.div`
  flex: 1 0;
  transform: scaleY(0.68);
  max-width: 16px; // width of triangle-white.svg

  &:last-child {
    text-align: right;
  }

  > img {
    filter: url(#${props => props.filterId});
    -webkit-filter: url(#${props => props.filterId});
  }

  &.no-background-color {
    background-color: white;
  }
`

const InnerFlexContainer = styled.div`
  flex: 5 0;
  text-align: center;

  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  color: ${props => props.textColor};
`

const ProgressArrowBox = ({
  text,
  bgColor,
  textColor,
  filterId,
  isFirst = false,
}) => {
  return (
    <FlexItemBox background={bgColor} to="">
      <InnerFlexContainerArrow>
        {!isFirst && <img src={triangleWhiteSvg} />}
      </InnerFlexContainerArrow>
      <InnerFlexContainer textColor={textColor}>{text}</InnerFlexContainer>
      <InnerFlexContainerArrow
        filterId={filterId}
        className="no-background-color"
        style={{ paddingTop: "4px" }}
      >
        <img src={triangleWhiteSvg} />
      </InnerFlexContainerArrow>

      {/* Ref https://stackoverflow.com/questions/32770808/overlay-non-transparent-area-of-image-with-css */}
      <div style={{ display: "none" }}>
        <svg>
          <filter id={filterId} type="matrix" colorInterpolationFilters="sRGB">
            <feColorMatrix
              type="matrix"
              values={colorHelper.hexToMatrix(bgColor)}
            />
          </filter>
        </svg>
      </div>
    </FlexItemBox>
  )
}

export default ProgressArrowBox
