import React from "react"
import styled from "styled-components"

import colorHelper from "@helpers/color"

const RelativePosition = styled.div`
  position: relative;
  width: 0;
  height: 0;
`

const PreviousNextJobLevel = styled.div`
  position: absolute;
  left: ${props => (props.isPrev ? "-36px" : "")};
  flex: 0.1;
  top: -11px;
  cursor: pointer;
  transform: scaleY(0.68);
  > img {
    filter: url(#${props => props.filterId});
    -webkit-filter: url(#${props => props.filterId});
    transform: ${props => (props.isPrev ? "scaleX(-1)" : "none")};
  }
`

// copy SVG directly, and add feColorMatrix filter
const ProgressPrevNext = ({
  onClickFn,
  tileArrowSvgComponent,
  filterId,
  colorHex,
  isPrev = false,
}) => {
  return (
    <RelativePosition>
      <PreviousNextJobLevel
        onClick={onClickFn}
        filterId={filterId}
        isPrev={isPrev}
      >
        <img src={tileArrowSvgComponent} />
      </PreviousNextJobLevel>

      {/* Ref https://stackoverflow.com/questions/32770808/overlay-non-transparent-area-of-image-with-css */}
      <div style={{ display: "none" }}>
        <svg>
          <filter id={filterId} type="matrix" colorInterpolationFilters="sRGB">
            <feColorMatrix
              type="matrix"
              values={colorHelper.hexToMatrix(colorHex)}
            />
          </filter>
        </svg>
      </div>
    </RelativePosition>
  )
}

export default ProgressPrevNext
